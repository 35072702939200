import mapValues from 'lodash/mapValues';

/**
 * Each detector must be a function that returns a boolean, with `true`
 * indicating that the corresponding feature is supported in the current browser.
 */
const detectors = {
  resizeObserver: () => typeof ResizeObserver !== 'undefined',
  pointerEvents: () => typeof PointerEvent !== 'undefined',
  cryptoSubtle: () => typeof crypto !== 'undefined' && !!crypto.subtle,
} as const;

type FeatureName = keyof typeof detectors;

const browserFeatures: Record<FeatureName, boolean> = mapValues(detectors, (detector) =>
  detector()
);

export default function getSupportedBrowserFeatures(): Record<FeatureName, boolean> {
  return browserFeatures;
}

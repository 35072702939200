import { isAuthenticated } from 'flatfox_common/config';
import { formatPhoneNumberToE164 } from 'flatfox_common/format';
import { sha256 } from 'flatfox_common/hash';

function setupLogging(form) {
  if (!form) {
    return;
  }

  const fieldSelectors = [
    'input[name=name]',
    'input[name=email]',
    'textarea[name=text]',
    'button[type=submit]',
  ];

  let started = false;

  function handleStarted() {
    if (!started) {
      logEventFacebook('ApplyForFlat', 'Started');
      started = true;
    }
  }

  // Log the 'Started' event when the user starts to fill out the
  // form
  for (let i = 0; i < fieldSelectors.length; i += 1) {
    const field = form.querySelector(fieldSelectors[i]);
    if (field) {
      field.addEventListener('click', handleStarted);
    }
  }
}

function setup() {
  const contactForms = document.querySelectorAll('form[name=flat-contact-form]');
  for (let i = 0; i < contactForms.length; i += 1) {
    setupLogging(contactForms[i]);
  }
}

async function submitContactRequest(event: any) {
  // stop submitting form because we use async functions
  event.preventDefault();
  try {
    let encryptedEmail;
    let encryptedPhoneNumber;

    if (!isAuthenticated()) {
      const emailElement = document.getElementById('id_email') as HTMLInputElement;
      const phoneElement = document.getElementById('id_phone_number') as HTMLInputElement;

      if (emailElement) {
        const email = emailElement.value;
        encryptedEmail = await sha256(email);
      }
      if (phoneElement) {
        const phone_number = phoneElement.value;
        const formattedPhoneNumber = formatPhoneNumberToE164(phone_number);
        encryptedPhoneNumber = await sha256(formattedPhoneNumber);
      }
    }

    dataLayerPush({
      event: 'contact_request',
      conversion_name: 'listing_information',
      section: 'contact_advertiser',
      tag_type: 'standard',
      user_email: encryptedEmail,
      user_phone: encryptedPhoneNumber,
    });
  } catch (ignore) {
    // the full dataLayer is not that important, important is that the form is submitted
    try {
      dataLayerPush({
        event: 'contact_request',
        conversion_name: 'listing_information',
        section: 'contact_advertiser',
        tag_type: 'standard',
      });
    } catch (ignore2) {
      // ignore
    }
  }
  event.target.submit();
}

window.flatfox.flat = { setup, submitContactRequest };

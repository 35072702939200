import { preventTranslatorCrashingReact } from './preventTranslatorCrashingReact';

// Add our mounting point. The following requires will add themselves to it,
// the bootloader will then call those.
window.flatfox = {};

// Get all our scripts and mount their setup function on window.flatfox.
require('credit_check/views/app');
require('flatfox/views/application/client');
require('flatfox/views/direct_submit');
require('flatfox/views/dossier/client');
require('flatfox/views/flat/details');
require('flatfox/views/listings/client');
require('flatfox/views/dashboard/client');
require('flatfox/views/search');
require('flatfox/views/favorite_listings/client');
require('flatfox_crm/client/');
require('flatfox_inventory/client');
require('flatfox_lease_termination/client');
require('flatfox_stats/client/app');
require('flatfox_tickets/client');
require('flatfox_transfer/client');
require('flatfox_messenger/client');
require('flatfox_viewing/client/');
require('flatfox_website/views/account');
require('flatfox_website/views/base/base');
require('flatfox_website/views/login');
require('insurance/client/app');
require('moving_services/client/app');
require('quickline_services/client/app');
require('utils/photoswipe/photoswipe');
require('flatfox_common/ui/augments');
require('client/apps/security_deposit_request');
require('flatfox_website/views/navbar');

// Kick off bootloader AFTER the modules were mounted above. The bootloader
// will kick off any function invocation whenever its loaded, hence they need
// to be around already.
const bootloader = require('flatfox_website/scripts/bootloader').default;

bootloader(window);

// Sets up a MutationObserver to watch for the <html> elements `lang` attribute.
// If the user uses a plugin to change the website language, it will also change the
// html lang attribute - when this happens we fire the  preventReactCrashingWhenTranslatorIsActive
// which should prevent crashes caused by react not being able to find a node.

const observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    if (mutation.attributeName === 'lang') {
      preventTranslatorCrashingReact();
      observer.disconnect();
    }
  });
});
observer.observe(document.documentElement, { attributes: true });

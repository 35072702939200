import isFunction from 'lodash/isFunction';

/**
  Construct a type with the properties of T1, overridden by properties of T2.

  Let's say we have type A:   |   And we want to have:
    type A = {                |     type AB = A & {
      a: string;              |       b: number;
      b: string;              |     };
    }                         |

  This will not work as properties from A have precedence over properties from B.

  This is where Override steps in:
  type AB = Override<A, { b: number }>

  Now AB is really:
  type AB = {
    a: string;
    b: number;
  }
*/
export type Override<T1, T2> = Omit<T1, keyof T2> & T2;

/** A value that can be `await`ed on. */
export type Awaitable<T> = T | PromiseLike<T>;

/** All possible value types of the given array type. */
export type ArrayMember<T extends unknown[]> = T[number];

declare const emptyObjectSymbol: unique symbol;

/**
 * Shorthand to declare an object that has no properties.
 *
 * See https://github.com/sindresorhus/type-fest/issues/395 for why
 * `Record<string, never>` is not sufficient.
 */
export type EmptyObject = { [emptyObjectSymbol]?: never };

/**
 * Flattens the type output to improve type hints shown in editors,
 * and making type error messages less cryptic for more complex typing shenanigans.
 *
 * @link https://github.com/sindresorhus/type-fest/blob/4f14bff7321b9a9876dca0719945423abd6b4da1/source/simplify.d.ts
 */
// There's typing magic going on here
// eslint-disable-next-line @typescript-eslint/ban-types
export type Simplify<T> = { [KeyType in keyof T]: T[KeyType] } & {};

/**
 * All the possible keys of the given union type.
 *
 * Example:
 *
 * ```ts
 * type AllPossibleKeys = KeysOfUnion<
 *   | {foo: string}
 *   | {bar: string}
 *   | {foo: number, baz: string}
 * >;
 *
 * // is equivalent to
 *
 * type AllPossibleKeys = 'foo' | 'bar' | 'baz';
 * ```
 */
// `any` is required for the typing magic
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type KeysOfUnion<T> = T extends any ? keyof T : never;

/**
 * Construct a tagged union discriminated by the value of the property name
 * given as `TagKey`, with `UnionMembers` providing the properties specific to
 * each member. Using this helper makes sure that the discriminator is always
 * present and that removes the need to remove that.
 *
 * NOTE: Use `EmptyObject` to indicate that the given member doesn't have any additional
 *       properties. `{}` as a type actually means any object in TypeScript.
 *
 * ```ts
 * type SomePayload = TaggedUnion<
 *   'payloadType',
 *   {
 *     valueChanged: { newValue: string };
 *     exit: EmptyObject;
 *   }
 * >;
 * ```
 *
 * is equivalent to
 *
 * ```ts
 * type SomePayload =
 *   | {
 *       payloadType: 'valueChanged';
 *       newValue: string;
 *     }
 *   | { payloadType: 'exit' };
 * ```
 */
export type TaggedUnion<
  TagKey extends string,
  UnionMembers extends Record<string, Record<string, unknown>>
> = TypedTaggedUnion<TagKey, string, UnionMembers>;

/** Same as `TaggedUnion`, but the type of the tag can be given to make sure all enum cases are covered. */
export type TypedTaggedUnion<
  TagKey extends string,
  TagType extends string,
  UnionMembers extends Record<TagType, Record<string, unknown>>
> = {
  [Name in keyof UnionMembers]: { [Key in TagKey]: Name } & UnionMembers[Name];
}[keyof UnionMembers];

/**
 * Helper to let the compiler check that a switch statement is exhaustive. Example:
 *
 * type OnOff = "on" | "off";
 *
 * const powerState: OnOff = "on";
 *
 * // This `switch` statement is missing a `case` statement:
 * switch(powerState) {
 *   case "on":
 *    // do stuff
 *    break;
 *   default:
 *    // The compiler determines that `powerState` could still contain `"off"` at this point,
 *    // thus making it incompatible with the `never` type constraint.
 *    assertUnreachable(powerState);
 * }
 *
 * // Now that we also handle `"off"`, the compiler is happy
 * switch(powerState) {
 *   case "on":
 *    // do stuff
 *    break;
 *   case "off":
 *    // do other stuff
 *    break;
 *   default:
 *    // The compiler determines that we can not reach here, inferring `powerState` to be
 *    // of type `never` at this point, making it compatible with `assertUnreachable()`
 *    assertUnreachable(powerState);
 * }
 */
export function assertUnreachable(x: never): never {
  throw new Error(`Unexpected value: ${x}`);
}

/**
 * Similar to `assertUnreachable()`, but doesn't cause an error at runtime.
 */
export function warnIfReachable(_x: never): void {
  // Do nothing, say nothing, be nothing.
}

/**
 * Throws a `TypeError` if the given value doesn't satisfy the given type guard.
 */
export function guardedCast<SourceType, DestinationType extends SourceType>(
  value: SourceType,
  guard: (val: SourceType) => val is DestinationType,
  message = `Unexpected value ${value}`
): DestinationType {
  if (guard(value)) {
    return value;
  }

  throw new TypeError(message);
}

/** Type guard to check whether the given key is valid for the given object. */
export function isKeyOf<T extends object>(
  key: PropertyKey | undefined,
  obj: T
): key is keyof T {
  if (typeof key === 'undefined') {
    return false;
  }

  return key in obj;
}

/** Type guard to check that the given value is neither `null` nor `undefined`. */
export function isPresent<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

/**
 * Calls `getter` with the value and return the result if the value is neither `null` nor
 * `undefined`. Returns `undefined` is `value` was absent.
 */
export function ifPresent<ValueType, ResultType>(
  value: ValueType | null | undefined,
  getter: (val: ValueType) => ResultType
): ResultType | undefined;
/**
 * Calls `getter` with if the value is neither `null` nor `undefined`. If `value` is
 * absent, it returns `fallback` if `fallback` is a value, otherwise it will call
 * `fallback` and return its return value.
 */
export function ifPresent<ValueType, ResultType, FallbackType>(
  value: ValueType | null | undefined,
  getter: (val: ValueType) => ResultType,
  fallback: (() => FallbackType) | FallbackType
): ResultType | FallbackType;
export function ifPresent<ValueType, ResultType, FallbackType>(
  value: ValueType | null | undefined,
  getter: (val: ValueType) => ResultType,
  fallback?: (() => FallbackType) | FallbackType
): ResultType | FallbackType | undefined {
  if (isPresent(value)) {
    return getter(value);
  }

  return isFunction(fallback) ? fallback() : fallback;
}

export function assertPresent<T>(
  maybeValue: T | null | undefined,
  errorMessage = 'FOX-58492: Expected value to be present'
): asserts maybeValue is T {
  if (!isPresent(maybeValue)) {
    throw new TypeError(errorMessage);
  }
}

/** Like `Required`, but for a single property. */
export type WithRequired<T, K extends keyof T> = T & Required<Pick<T, K>>;
